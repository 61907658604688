import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OnboardingRoutingModule } from './onboarding-routing.module';
import { OnboardingComponent } from './onboarding.component';
import { FormsModule } from '@angular/forms';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ICON_SETTINGS, IconsModule } from '@progress/kendo-angular-icons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { CommonsModule } from '../core/common/common.module';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@auth0/auth0-angular';
import { AppSettings } from 'src/assets/config/app-settings';
import { settingsFactory } from '../app.module';
import { HttpService } from '../core/services/http.service';
import { SessionService } from '../core/services/session.service';
import { SubscriptionsService } from '../core/services/subscriptions.service';
import { UserService } from '../core/services/user.service';
import { AccountComponent } from './account/account.component';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';

@NgModule({
  declarations: [OnboardingComponent, AccountComponent],
  imports: [
    CommonModule,
    OnboardingRoutingModule,
    CommonsModule,
    FormsModule,
    LayoutModule,
    IconsModule,
    InputsModule,
    LabelModule,
    ButtonsModule,
    DropDownsModule,
    IndicatorsModule,
    ProgressBarModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: settingsFactory,
      deps: [HttpClient, HttpService, AppSettings, AuthService, SessionService, UserService, SubscriptionsService],
      multi: true,
    },
    { provide: ICON_SETTINGS, useValue: { type: 'font' } }
  ],
})
export class OnboardingModule {}
