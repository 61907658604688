import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { map } from 'rxjs';
import { Product } from 'src/app/core/models/plan.mode';
import { OnboardingStatus } from 'src/app/core/models/user.model';
import { SessionService } from 'src/app/core/services/session.service';
import { SubscriptionsService } from 'src/app/core/services/subscriptions.service';
import { UserService } from 'src/app/core/services/user.service';
import { AppSettings } from 'src/assets/config/app-settings';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss'],
})
export class PlansComponent implements OnInit {
  public subscriptions: Product[] = [];
  public skeletonSubscription = new Array(3);
  public skeletonSubscriptionFeaturesList = new Array(7);

  @Output() changeStep = new EventEmitter<any>();
  @Output() showLoader = new EventEmitter<any>();
  @Output() message = new EventEmitter<string>();

  public authUserId: string | undefined;
  public currentSubscription: any;
  userId: string | undefined;

  constructor(
    private subscriptionsService: SubscriptionsService,
    private session: SessionService,
    private userService: UserService,
    public settings: AppSettings
  ) {
  }

  ngOnInit(): void {
    this.userId = this.session.user?.userId;
    this.authUserId = this.session.user?.externalId;
    this.getSubscriptions();
    const message = "Let's get this party started! Complete your onboarding<br> adventure and you'll be ready to leap into the next exciting steps!"
    this.message.emit(message);
  }

  // getSubscriptions() {
  //   this.subscriptionsService.getSubscriptions()
  //     .subscribe((res: any) => {
  //       if (res && res.subscriptions) {
  //         this.subscriptions = res.subscriptions.sort((a: any, b: any) => {
  //           return a.state === 'active' ? -1 : 1;
  //         });
  //       } else {
  //         console.error('Invalid API response format');
  //       }
  //     });
  // }

  getSubscriptions() {
    this.subscriptionsService.getSubscriptions().subscribe((res: any) => {
      if (res && res.subscriptions) {
        this.subscriptions = res.subscriptions.sort((a: any, b: any) => {
          // Prioritize active state
          if (a.state === 'active' && b.state !== 'active') {
            return -1;
          }
          if (b.state === 'active' && a.state !== 'active') {
            return 1;
          }

          // If both have the same state, sort by unitAmount
          let unitAmountA = parseFloat(a.currencies[0].unitAmount);
          let unitAmountB = parseFloat(b.currencies[0].unitAmount);
          return unitAmountA - unitAmountB;
        });
      } else {
        console.error('Invalid API response format');
      }
    });
  }

  onSelectPlan(priceId: string) {
    this.showLoader.emit(true);
    const requestData = {
      selectedPlanCode: priceId,
      onboardingStatus: OnboardingStatus.account,
    };
    return this.userService.updateUserSelectedStatusPlan(this.userId, requestData).subscribe(() => {
      this.showLoader.emit(false);
      this.changeStep.emit(OnboardingStatus.account);
    });
  }
}
