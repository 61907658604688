import { Subscriptions } from './../../core/models/subscriptions';
import { SubscriptionsService } from '../../core/services/subscriptions.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss'],
})
export class SubscriptionsComponent implements OnInit {
  public subscriptions: Subscriptions[] = [];
  public clientToken!: string;
  public skeletonSubscription = new Array(3);
  public skeletonSubscriptionFeaturesList = new Array(7);

  constructor(private subscriptionsService: SubscriptionsService, private router: Router, private localStorage: LocalStorageService) { }

  ngOnInit(): void {
    this.getSubscriptions();
  }

  getSubscriptions() {
    this.subscriptionsService.getSubscriptions().subscribe((res: any) => {
      this.clientToken = res.clientToken;
      this.subscriptions = res.subscriptions;
    });
  }

  onSelectPlan(subscription: Subscriptions) {
    const subscriptionParam = {
      clientToken: this.clientToken,
      planId: subscription.id,
    };
    this.localStorage.setItem('subscription', subscriptionParam);
    this.router.navigate(['/payment']);
  }
}
