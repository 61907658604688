<kendo-tabstrip #tabs class="mt-8" (tabSelect)="tabSelect($event)">
  <kendo-tabstrip-tab title="Port Number"
    [selected]="this.session.user?.onboardingStatus === OnboardingStatus.port">
    <ng-template kendoTabTitle>
      <i class="fa-duotone fa-phone"></i>&nbsp;Port Number
    </ng-template>
    <ng-template kendoTabContent>
      <div class="p-5" [formGroup]="portForm">
        <div class="row">
          <div class="mb-5">
            Would you prefer to <strong>keep</strong> your current phone number or get a new one for our services?</div>
          </div>
          <div class="row">
            <div class="col-auto">
              <input type="radio" name="portOption" value="port" #port formControlName="portOption" kendoRadioButton />
            </div>
            <div class="col"><strong><em>Keep your current number</em></strong>, we can assist with the number porting
            process.
            Please fill out LOA (<em>letter of agreement in the next step</em>) and upload your latest billing statement
            form your phone carrier.
            @if (!portForm.controls.billingStatementUrl.value) {
              <div>
                <label style="display: block; margin: .8em 0 .2em">
                  <em>
                    <strong>Please upload</strong>&nbsp;&nbsp;<i class="fa-duotone fa-cloud-arrow-up"></i> your latest
                    phone billing statement from your carrier
                  </em></label>
                  <kendo-fileselect class="mt-5 custom-fileselect" style="width: 75%" [multiple]="false"
                    formControlName="billingStatementFiles">
                  </kendo-fileselect>
                </div>
              }
              @if (portForm.controls.billingStatementUrl.value) {
                <div>
                  <div class="mt-5"><a [href]="portForm.controls.billingStatementUrl.value">{{portForm.controls.billingStatementUrl.value}}</a></div>
                  <button (click)="removeFile()" class="btn btn-icon icon-35 btn-light-danger mt-5" type="button">
                    <i class="fa-duotone fa-xmark"></i>&nbsp;&nbsp;
                    <span class="label7">Remove</span>
                  </button>
                </div>
              }
              <br />
            </div>
          </div>
          <div class="row">
            <div class="col-auto">
              <input type="radio" name="portOption" value="buy" #buy formControlName="portOption" kendoRadioButton />
            </div>
            <div class="col">
              <strong><em>Buy a new number</em></strong>, were here to provide full assistance in finding the ideal
              number to complement your services.
            </div>
          </div>
          <div class="k-form-buttons col-12 px-3 m-0 d-flex justify-content-end mt-10">
            <button kendoButton (click)="portPrevious()" class="btn btn-light-info btn-form align-self-start w-auto mr-1">
              <span class="fa-light fa-angle-left"></span>&nbsp;
              <span class="btn-label">Previous</span>
            </button>

            <button kendoButton (click)="portNext()" [disabled]="!portForm.valid"
              class="btn btn-light-primary btn-form align-self-start w-auto">
              <span class="btn-label">Next</span>&nbsp;
              <span class="fa-light fa-angle-right"></span>
            </button>
          </div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab title="E-Sign" [disabled]="!portForm.valid"
      [selected]="this.session.user?.onboardingStatus === OnboardingStatus.esign">
      <ng-template kendoTabTitle>
        <i class="fa-duotone fa-signature"></i>&nbsp;E-Sign
      </ng-template>
      <ng-template kendoTabContent>
        <div class="container fs-7 p-12 shadow">
          @if (pdfUrl; as url) {
            <div class="row">
              <div class="col d-flex justify-content-between pt-0">
                <button kendoButton class="btn-sm text-dark border mb-5 mt-0" themeColor="warning"
                  (click)="redo()"><strong>Re-Sign</strong>&nbsp;<i class="fa-duotone fa-pen"></i></button>
                  <div>
                    <button kendoButton class="btn-sm text-dark border mb-5 mt-0" style="margin-right: 5px"
                      themeColor="none" (click)="esignPrevious()"><i
                      class="fa-duotone fa-arrow-left"></i>&nbsp;<strong>Previous</strong></button>
                      <button kendoButton class="btn-sm text-dark border mb-5 mt-0" themeColor="success"
                        (click)="esignNext()"><strong>Next</strong>&nbsp;<i class="fa-duotone fa-arrow-right"></i></button>
                      </div>
                    </div>
                  </div>
                  <kendo-pdfviewer style="height: 11in;" [url]="url"></kendo-pdfviewer>
                  <div class="row mt-5">
                    <div class="col d-flex justify-content-between pt-0">
                      <button kendoButton class="btn-sm text-dark border mb-5 mt-0" themeColor="warning"
                        (click)="redo()"><strong>Re-Sign</strong>&nbsp;<i class="fa-duotone fa-pen"></i></button>
                        <div>
                          <button kendoButton class="btn-sm text-dark border mb-5 mt-0" style="margin-right: 5px"
                            themeColor="none" (click)="esignPrevious()"><i
                            class="fa-duotone fa-arrow-left"></i>&nbsp;<strong>Previous</strong></button>
                            <button kendoButton class="btn-sm text-dark border mb-5 mt-0" themeColor="success"
                              (click)="esignNext()"><strong>Next</strong>&nbsp;<i class="fa-duotone fa-arrow-right"></i></button>
                            </div>
                          </div>
                        </div>
                      } @else {
                        <div class="p-10" style="background-color: #EFEDEC; border-width: 1px; border-style: solid;">
                          <div class="p-2 contract-container" style="background-color: white;">
                            <div class="row">
                              <div class="col d-flex justify-content-end">
                                <button kendoButton class="btn-sm text-dark border" style="margin-right: 5px" themeColor="none"
                                  (click)="esignPrevious()"><i
                                  class="fa-duotone fa-arrow-left"></i>&nbsp;<strong>Previous</strong></button>
                                  <button kendoButton class="btn-sm text-dark border" themeColor="warning"
                                    [disabled]="!contractForm.valid" (click)="exportToPdf()"><strong>Finish</strong>&nbsp;<i
                                  class="fa-duotone fa-arrow-right"></i></button>
                                </div>
                              </div>
                            </div>
                            <div style="max-width: 750px; margin: auto; border: 1px; border-style: solid; border-top-width: 0;"
                              class="pt-2">
                              <h6>&nbsp;&nbsp;&nbsp;&nbsp;RGRID E-SIGN <i class="fa-duotone fa-pen"></i> / LETTER OF AGENCY (LOA)</h6>
                            </div>
                            <div class="contract-container" style="background-color: white; border-top-width: 0;">
                              <div #contractDiv class="p-12 pt-6 d-flex flex-column" style="height: 11n">
                                <div class="d-flex justify-content-end">
                                  <div #logo class="d-flex flex-column align-items-end">
                                    <!-- <img alt="Logo" src="assets/media/logos/Relaygrid9.svg" class="h-lg-45px mb-2" /> -->
                                    <!-- <img alt="Logo" src="assets/media/logos/Relaygrid9.svg" height="45px" /> -->
                                    <img alt="Logo" src="assets/media/logos/contract-logo.bmp" />
                                    <!-- <div class="text-start fs-8">
                                    120 E FM 544 Suite 72 #432&nbsp;&nbsp;|&nbsp;&nbsp;Murphy, TX 75094
                                    <br />
                                  </div> -->
                                </div>
                              </div>
                              <div class="text-center mb-2">
                                <h6>Letter of Agency (LOA)</h6>
                              </div>
                              <form [formGroup]="contractForm!">
                                This letter authorizes rgrid LLC to initiate a port request. All information must be entered exactly
                                as
                                shown
                                on
                                the customer service record (CSR) of the current carrier. In addition to completing this form, you
                                will need
                                to
                                provide a copy of your latest bill/invoice.
                                <br />
                                <div class="row mt-2">
                                  <div class="col">
                                    <div><strong>Account or Company Name</strong></div>
                                    <input kendoTextBox type="text" formControlName="companyName" placeholder="Company Name"
                                      [style.width.px]="250" style="text-transform: uppercase;">
                                    </div>
                                    <div class="col">
                                      <strong>Email</strong><br />
                                      <input kendoTextBox type="email" formControlName="email" [style.width.px]="250"
                                        placeholder="Email">
                                      </div>
                                      <div class="col">
                                        <strong>EIN</strong><br />
                                        <input kendoTextBox type="ein" formControlName="ein" [style.width.px]="100" placeholder="EIN"
                                          value="88-1706201" maxlength="10">
                                        </div>
                                      </div>
                                      <div class="mt-3 mb-1">
                                        <strong>From The Customer Service Record (CSR)</strong>
                                        <br />
                                        Use the Service Address, not the Billing Address (unless they are the same)
                                      </div>
                                      <div class="row">
                                        <div class="col-5" style="border: 0; border-style: solid;">
                                          Street Number<br />
                                          <input kendoTextBox type="text" formControlName="address" [style.width.%]="100"
                                            placeholder="Address">
                                          </div>
                                          <div class="col-3">
                                            City<br />
                                            <input kendoTextBox type="text" formControlName="city" [style.width.%]="100" placeholder="City">
                                          </div>
                                          <div class="col-2">
                                            State<br />
                                            <input kendoTextBox type="text" formControlName="state" [style.width.%]="100" placeholder="State">
                                          </div>
                                          <div class="col-2">
                                            Zip<br />
                                            <input kendoTextBox type="text" formControlName="zip" [style.width.%]="100" placeholder="Zip">
                                          </div>
                                        </div>
                                        <div class="mt-3"><strong>Current Carrier Information</strong></div>
                                        <div class="row">
                                          <div class="col-6">
                                            Carrier<br />
                                            <input kendoTextBox type="text" formControlName="carrier" [style.width.%]="100"
                                              placeholder="Current Carrier">
                                            </div>
                                            <div class="col-6">
                                              Billing Telephone Number (BTN)<br />
                                              <input kendoTextBox type="text" formControlName="carrierBtn" [style.width.%]="100"
                                                placeholder="Billing Telephone Number (BTN)" [maxlength]="12" [maxlength]="12">
                                              </div>
                                            </div>
                                            <div class="mt-3"><strong>Number to Be Ported</strong></div>
                                            Separate with commas. For ranges, use a dash (i.e. (216) 321-5000-(216) 321-5999). Please make a note
                                            below
                                            if
                                            you
                                            are attaching a separate list of numbers.
                                            <br />
                                            <input kendoTextBox type="text" formControlName="numberToPort" [style.width.px]="300"
                                              placeholder="Number to Port">
                                              <br />
                                              <div class="mt-4">
                                                This Letter of Agency (“LOA”) hereby authorizes release of all customer proprietary network
                                                information
                                                (“CPNI”),
                                                as defined in 47 U.S.C. §222, to rgrid LLC. Such CPNI shall include but not be limited to customer
                                                name
                                                and
                                                number, billing records, service records and network and equipment records for the purpose of
                                                providing
                                                telecommunications or information services. This LOA will become effective on signature date and
                                                will
                                                remain
                                                in
                                                effect unless revoked in writing prior to that date.
                                              </div>
                                              <ol class="fs-7 mt-2">
                                                <li>Parties acknowledge that [Your Company Name] has obtained customer proprietary network
                                                  information
                                                  (“CPNI”)
                                                as that term is defined in 47 U.S.C.§222.</li>
                                                <li><strong>{{ contractForm.controls.companyName.value }}</strong> authorizes rgrid LLC to use,
                                                disclose
                                                or
                                                access such CPNI
                                                as needed for the provision of telecommunications
                                                services to <strong>{{ contractForm.controls.companyName.value }}</strong> end user customers.
                                                Such use
                                                and disclosure includes, but is not limited to, population of CNAM databases by third party
                                                providers.
                                              </li>
                                              <li>Parties acknowledge that pursuant to 47 C.F.R. §64.2005, <strong>{{
                                              contractForm.controls.companyName.value }}</strong>
                                              may use, disclose, or permit access to CPNI for the purpose of providing service without
                                              authorization
                                              from its customers.
                                            </li>
                                            <li><strong>{{ contractForm.controls.companyName.value }}</strong> agrees that it will not require
                                            rgrid
                                            LLC to use, disclose or access CPNI for any reason other than for
                                            the
                                            provision of telecommunications or information services, as provided in 47 C.F.R. §64.2005(a). To
                                            the
                                            extent
                                            that rgrid LLC performance of this Agreement includes activities outside the scope of those
                                            permitted in
                                            47
                                            C.F.R. §64.2005(a), <strong>{{ contractForm.controls.companyName.value }}</strong> will provide
                                            rgrid
                                            LLC with any necessary written customer authorization for the use,
                                          disclosure or access to CPNI prior to rgrid LLC performance of those activities. </li>
                                          <li>rgrid LLC agrees to take all reasonable steps to protect CPNI provided to it by <strong>{{
                                          contractForm.controls.companyName.value }}</strong> in compliance with 47
                                          U.S.C.
                                        §222. </li>
                                      </ol>
                                      <div class="row mt-4">
                                        <div class="col-6">
                                          Authorized Signature <i class="fa-duotone fa-signature"></i><br />
                                          <canvas #signaturePadCanvas style="border: 1px solid black; height: 35px; width: 100%;"></canvas>
                                          <a href="javascript: void(0)" class="link-primary fs-8" (click)="clear(this)">Reset Signature <i
                                          class="fa-duotone fa-eraser"></i></a>
                                        </div>
                                        <div class="col-4">
                                          Printed Name <i class="fa-duotone fa-input-text"></i><br />
                                          <input kendoTextBox type="text" formControlName="name" style="width: 100%;"
                                            placeholder="Printed Name">
                                          </div>
                                          <div class="col-2">
                                            Date <i class="fa-duotone fa-calendar-days"></i><br />
                                            <kendo-dateinput #input [format]="dateFormat" formControlName="date" style="width: 100%;"
                                            placeholder="Date" [disabled]="true"></kendo-dateinput>
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                  <div class="d-flex flex-column align-items-end p-2 contract-container"
                                    style="background-color: white; border-top-width: 0;">
                                    <div>
                                      <button kendoButton class="btn-sm text-dark border" style="margin-right: 5px" themeColor="none"
                                        (click)="esignPrevious()"><i
                                        class="fa-duotone fa-arrow-left"></i>&nbsp;<strong>Previous</strong></button>
                                        <button kendoButton class="btn-sm text-dark " themeColor="warning" (click)="exportToPdf()"
                                          [disabled]="!contractForm.valid"><strong>Finish</strong>&nbsp;<i
                                        class="fa-duotone fa-arrow-right"></i></button>
                                      </div>
                                    </div>
                                  </div>
                                }

                              </div>
                            </ng-template>
                          </kendo-tabstrip-tab>
                        </kendo-tabstrip>
                        <br />
                        <br />