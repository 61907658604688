import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Invoice } from 'src/app/core/models/invoice.model';
import { InvoicesService } from 'src/app/core/services/invoices.service';
import { SessionService } from 'src/app/core/services/session.service';
import { SubscriptionsService } from 'src/app/core/services/subscriptions.service';
import { DrawerComponent } from '@progress/kendo-angular-layout';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';


@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss'],
})
export class InvoiceComponent implements OnInit {
  @ViewChild('drawer') drawer!: DrawerComponent;
  public invoiceDetail!: Invoice;
  public invoicePdfUrl!: string;
  @Output() showLoader = new EventEmitter<any>();
  @Output() changeStep = new EventEmitter<any>();
  @Output() message = new EventEmitter<string>();

  constructor(
    public subscriptionsService: SubscriptionsService,
    private router: Router,
    private session: SessionService,
    private invoiceService: InvoicesService,
    private localStorageService: LocalStorageService // Inject the service

  ) { }

  ngOnInit(): void {
    this.getInvoicePdfUrl();
    const message = "Welcome to <strong>rgrid.io</strong> To complete your onboarding, simply click the 'Finish' button and we'll create a customized </br>call flow template suited for your business. If you have any questions, our support team is always here to help."
    this.message.emit(message);
  }

  getInvoicePdfUrl() {
    setTimeout(() => {
      this.showLoader.emit(true);
    });
    this.subscriptionsService.getInvoicePdf().subscribe(
      (url: string) => {
        this.invoicePdfUrl = url;
        this.showLoader.emit(false);
      },
      (error) => {
        console.error('Error fetching invoice PDF URL:', error);
      },
      () => {
        this.showLoader.emit(false);
      }
    );
  }

  finish() {
    document.getElementsByTagName('kendo-drawer-content')[0].scrollTo(0, 0);
    this.showLoader.emit(true);
    const customerId = this.session.user?.customer?._id!;
    const userId = this.session.user?.userId!;
    const numberId = this.session.user?.customer?.numbers![0]._id!;
  
    // Retrieve timeZone from local storage
    this.localStorageService.getItem<string>('userTimeZone').subscribe(timeZone => {
      if (!timeZone) {
        // Handle the case where timeZone is not set in local storage
        console.error('TimeZone not found in local storage');
        // Optionally, emit false to hide the loader or handle the error as needed
        timeZone = "America/Chicago";
      }

        // Call the finish method with timeZone
        this.invoiceService.finish(customerId, userId, numberId, timeZone)
          .subscribe({
            next: (res) => {
              this.session.user!.customer?.numbers!.push(res.number);
              this.session.user!.selectedNumber = this.session.user!.customer?.numbers![0];
              this.session.user!.customer = res.customer;
              this.session.user!.onboardingStatus = res.user.onboardingStatus;
              this.router.navigate(['/menus']);
            },
            error: () => {
              this.showLoader.emit(false);
            },
            complete: () => {
              this.showLoader.emit(false);
            }
          });

    });
  }
  



}


