import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { OnboardingGuard } from './core/guards/onboarding.guard';
import { PortalLayoutComponent } from './shared/layouts/portal-layout/portal-layout.component';
import { EfaxComponent } from './features/efax/efax.component';

const routes: Routes = [
  {
    path: '',
    component: PortalLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'menus',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        canActivate: [OnboardingGuard],
        loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
        data: {
          title: 'Dashboard',
        },
      },
      {
        path: 'menus',
        canActivate: [OnboardingGuard],
        loadChildren: () => import('./menus/menus.module').then((m) => m.MenusModule),
        data: {
          title: 'Menus',
        },
      },
      {
        path: 'voice',
        canActivate: [OnboardingGuard],
        loadChildren: () => import('./messages/messages.module').then((m) => m.MessagesModule),
        data: {
          title: 'Voice',
        },
      },
      {
        path: 'account',
        canActivate: [OnboardingGuard],
        loadChildren: () => import('./account/account-routing.module').then((m) => m.AccountRoutingModule),
        data: {
          title: 'Account',
        },
      },
      {
        path: 'availability',
        canActivate: [OnboardingGuard],
        loadChildren: () =>
          import('./availability/availability-routing.module').then((m) => m.AvailabilityRoutingModule),
        data: {
          title: 'availability',
        },
      },
      {
        path: 'payment',
        canActivate: [OnboardingGuard],
        loadChildren: () => import('./features/payment/payment-routing.module').then((m) => m.PaymentRoutingModule),
        data: {
          title: 'Payment',
        },
      },
      {
        path: 'admin',
        canActivate: [OnboardingGuard],
        loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
        data: {
          title: 'admin',
        },
      },
      {
        path: 'payment',
        canActivate: [OnboardingGuard],
        loadChildren: () => import('./features/payment/payment.module').then((m) => m.PaymentModule),
        data: {
          title: 'payment',
        },
      },

      {
        path: 'invoices',
        canActivate: [OnboardingGuard],
        loadChildren: () => import('./invoices/invoices.module').then((m) => m.InvoicesModule),
        data: {
          title: 'invoices',
        },
      },
      {
        path: 'design',
        canActivate: [OnboardingGuard],
        loadChildren: () => import('./design/design-routing.module').then((m) => m.DesignRoutingModule),
        data: {
          title: 'design',
        },
      },
      {
        path: 'invoice/:id',
        canActivate: [OnboardingGuard],
        loadChildren: () =>
          import('./features/payment/components/invoice/invoice-routing.module').then((m) => m.InvoiceRoutingModule),
        data: {
          title: 'invoice',
        },
      },
      {
        path: 'onboarding',
        loadChildren: () => import('./onboarding/onboarding.module').then((m) => m.OnboardingModule),
      },
      {
        path: 'sms',
        loadChildren: () => import('./features/sms/sms.module').then((m) => m.SmsModule),
      },
        {
          path: 'efaxes',
          canActivate: [OnboardingGuard],
          loadComponent: () => 
            import('./features/efax/efax.component').then(
              (mod) => mod.EfaxComponent
            ),
          data: {
            title: 'eFaxes',
          },
        },
      { path: 'numbers',
        canActivate: [OnboardingGuard],
        loadChildren: () => import('./features/numbers/numbers.module').then((m) => m.NumbersModule) },
      {
        path: 'voicemails',
        canActivate:[OnboardingGuard],
        loadChildren: () => import('./features/voicemails/voicemails.module').then((m) => m.VoicemailsModule),
      },
      { path: 'extensions',
        canActivate: [OnboardingGuard],
        loadChildren: () => import('./features/extensions/extensions.module').then(m => m.ExtensionsModule) },      
      { path: 'speech',
        canActivate: [OnboardingGuard],
        loadChildren: () => import('./features/speech/speech.module').then(m => m.SpeechModule) },  
    ],
  },  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
