import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { StepperActivateEvent, StepperComponent } from '@progress/kendo-angular-layout';
import { LocalStorageService } from '../core/services/local-storage.service';
import { OnboardingStatus } from '../core/models/user.model';
import { UserService } from '../core/services/user.service';
import { SseService } from 'src/app/core/services/sse.service';
import { NgZone } from '@angular/core';
import { Subject, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
})
export class OnboardingComponent implements OnInit, OnDestroy {
  private unsubscribe = new Subject<void>();

  public steps = [
    { label: 'Select Plan', isValid: true, iconClass: 'fa-duotone fa-bullseye-pointer' },
    { label: 'Account', isValid: true, iconClass: 'fa-duotone fa-user' },
    { label: 'Port #', isValid: true, iconClass: 'fa-duotone fa-phone-volume' },
    { label: 'eSign', isValid: true, iconClass: 'fa-duotone fa-pen-field', optional: true },
    { label: 'Payment', isValid: true, iconClass: 'fa-duotone fa-credit-card' },
    { label: 'Invoice', isValid: true, iconClass: 'fa-duotone fa-file-invoice' },
  ];

  @ViewChild('stepper', { static: true })
  public stepper!: StepperComponent;
  public showLoader = false;
  public event = { message: 'step', progress: 0 };
  public currentStep = OnboardingStatus.none;
  public status = OnboardingStatus;
  public _message = '';

  public message(value: string) {
    if (value) this._message = value;
    else this._message = "Let's get this party started! Complete your onboarding<br /> adventure and you'll be ready to leap into the next exciting steps!";
  }

  constructor(
    private router: Router,
    private localStorage: LocalStorageService,
    private userService: UserService,
    private sse: SseService,
    private ngZone: NgZone,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.goToStep();
    this.sse
      .getServerSentEvent()
      .pipe(
        takeUntil(this.unsubscribe),
        filter((x) => x.topic === 'onboarding')
      )
      .subscribe({
        next: (sseEvent) => {
          this.ngZone.run(() => {
            this.event = sseEvent;
            console.log(this.event);
          });
        },
      });
  }

  ngOnDestroy(): void {
    this.localStorage.removeItem('subscription');
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public activate(e: StepperActivateEvent): void {}

  public goToStep(nextStep: OnboardingStatus | undefined = undefined) {
    this.event.progress = 0;
    this.userService
      .getUser(true)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((user) => {
        if (user.onboardingStatus! < OnboardingStatus.finished) {
          if (nextStep !== undefined) {
            this.currentStep = nextStep;
          } else {
            this.currentStep = user.onboardingStatus!;
          }
          const url = `/onboarding/${OnboardingStatus[this.currentStep]}`;
          this.router.navigate([url]);
        } else if (user?.onboardingStatus === OnboardingStatus.finished) {
          this.router.navigate(['/menus']);
        }
        this.changeDetectorRef.detectChanges();
      });
  }

  onChangeLoader(event: any) {
    this.showLoader = event;
  }
}
