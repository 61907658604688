import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { User } from '@auth0/auth0-angular';
import { NotificationService } from '@progress/kendo-angular-notification';
import { concat, forkJoin, last, take } from 'rxjs';
import { Customer } from 'src/app/core/models/customer.model';
import { States } from 'src/app/core/models/states.model';
import { OnboardingStatus } from 'src/app/core/models/user.model';
import { CustomerService } from 'src/app/core/services/customer.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { SessionService } from 'src/app/core/services/session.service';
import { StatesService } from 'src/app/core/services/states.service';
import { UserService } from 'src/app/core/services/user.service';
import { timeZonesMapped } from 'src/app/core/constants/time-zones';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit {
  @Output() changeStep = new EventEmitter<any>();
  @Output() showLoader = new EventEmitter<any>();
  @Output() message = new EventEmitter<string>();
  
  public selectedState = '';
  public statesList: Array<States> = [];
  public user: any = {};
  public isFormValid = true;
  public timeZonesMapped = timeZonesMapped;

  constructor(
    private userService: UserService,
    private customerService: CustomerService,
    private statesUtilService: StatesService,
    private userSession: SessionService,
    private notification: NotificationService,
    private localStorage: LocalStorageService) { }

  ngOnInit(): void {
    this.getCurrentUserFromApi();
    this.getStatesList();
    const message = "Complete Your Account Details to Move Forward"
    this.message.emit(message);
  }

  getCurrentUserFromApi() {
    const user$ = this.userService.getUserByExternalId(true);
    const userTimeZone$ = this.localStorage.getItem('userTimeZone').pipe(take(1));

    forkJoin([user$, userTimeZone$]).subscribe(([user, userTimeZone]) => {
      this.user = user;
      this.user.companyName = user.customer?.company?.name || null;
      this.user.companyPhone = user.customer?.company?.phone  || null;
      this.user.companyAddress1 = user.customer?.company?.address1  || null;
      this.user.companyAddress2 = user.customer?.company?.address2  || null;
      this.user.companyCity = user.customer?.company?.city  || null;
      this.user.companyState = user.customer?.company?.state  || null;
      this.user.companyZip = user.customer?.company?.zip  || null;

      if (userTimeZone) {
        this.user.timeZone = userTimeZone;
      }
      else {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (this.timeZonesMapped.find((x) => x.value === timeZone)) 
          this.user.timeZone = timeZone;
      }
    });
  }

  getStatesList() {
    this.statesList = this.statesUtilService.getStatesList();
  }

  previous() {
    this.changeStep.emit(0);
  }

  save() {
    this.showLoader.emit(true);
    const currentUserId = this.userSession.user?.userId || '';
    const customerId = this.userSession.user?.customer?._id || '';
    const userData: any = this.userService.getUserFormData(this.user);
    const customerData: Customer & { userId: string } = {
      userId: this.userSession.user?.userId!,
      company: this.customerService.getCustomerFormData(this.user)
    };
    concat(
      this.userService.saveUser(currentUserId, userData),
      this.customerService.updateCustomer(customerId, customerData),
      this.localStorage.setItem('userTimeZone', this.user.timeZone).pipe(take(1))
    )
      .pipe(last())
      .subscribe(() => {
        this.notification.show({
          hideAfter: 3000,
          content: 'Your account changes have been saved. Time for tea!',
          cssClass: 'button-notification',
          animation: { type: 'slide', duration: 400 },
          position: { horizontal: 'center', vertical: 'top' },
          type: { style: 'success', icon: true },
        });
        this.showLoader.emit(false);
        this.changeStep.emit(OnboardingStatus.port);
      });
  }
}
