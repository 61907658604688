<div class="card">
  <app-user-info 
    [statesList]="statesList" 
    [(user)]="user" 
    [(validForm)]="isFormValid">
  </app-user-info>
  <div class="card-footer border-0">
    <div class="k-form-buttons col-12 px-3 m-0 d-flex justify-content-end">
      <button kendoButton (click)="previous()" class="btn btn-light-info btn-form align-self-start w-auto mr-1">
        <span class="fa-light fa-angle-left"></span>&nbsp;
        <span class="btn-label">Previous</span>
      </button>

      <button kendoButton (click)="save()" [disabled]="!isFormValid" class="btn btn-light-primary btn-form align-self-start w-auto">
        <span class="btn-label">Next</span>&nbsp;
        <span class="fa-light fa-angle-right"></span>
      </button>
    </div>
  </div>
</div>