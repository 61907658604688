import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonsModule } from '../core/common/common.module';
import { AccountComponent } from './account.component';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { LayoutModule } from "@progress/kendo-angular-layout"

@NgModule({
  declarations: [AccountComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, CommonsModule, UploadsModule, LayoutModule],
  providers: [],
})
export class AccountModule {}
