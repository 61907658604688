import { items } from '../../items';
import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { EventsService } from 'src/app/core/services/events.service';
import { SessionService } from 'src/app/core/services/session.service';
import { createIvrForm } from 'src/app/menus/services/customer-form.service';
import { CustomerService } from 'src/app/core/services/customer.service';
import { MenuHelpService } from 'src/app/menus/services/menu-help.service';
import { IVR } from 'src/app/core/models/customer.model';
import { ToasterService } from 'src/app/shared/notification-toast/toaster.service';
import { Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { NumberGridService } from 'src/app/features/numbers/services/number-grid.service';

@Component({
  selector: 'app-portal-header',
  templateUrl: './portal-header.component.html',
  styleUrls: ['./portal-header.component.scss'],
})
export class PortalHeaderComponent implements OnInit {
  @ViewChild('anchor', { static: false })
  public anchor!: ElementRef<HTMLElement>;
  public margin = { horizontal: -46, vertical: 7 };
  public toggleText = 'Hide';
  showThemePopup = false;
  public pagetitle = '';
  public ivrForm = createIvrForm();
  private unsubscribe = new Subject<void>();
  public switch = false;

  constructor(
    private location: Location,
    private events: EventsService,
    public session: SessionService,
    private customerService: CustomerService,
    public help: MenuHelpService,
    private toastNotification: ToasterService,
    private router: Router,
    private numberService: NumberGridService
  ) {}

  ngOnInit(): void {
    this.events.navigationClicked$.pipe(takeUntil(this.unsubscribe)).subscribe((x: any) => {
      this.pagetitle = x.text;
    });

    const item = items.find((obj) => obj.path?.toLowerCase() == this.location.path().toLowerCase());
    if (item) this.pagetitle = item.text!;

    this.ivrForm = createIvrForm(this.session.user?.customer);
    this.ivrForm.controls.forward?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((x) => {
      this.ivrForm.controls.forwardNumber?.setValue(this.session.user?.customer?.forwardNumber!);
    });

    this.setDefaultNumber();
  }

  private setDefaultNumber() {
    if (this.session.user?.customer?.numbers && this.session.user?.customer?.numbers.length > 0) {
      let defaultNumber = this.session.user.customer.numbers.find((number) => number.isDefault);      
      if(defaultNumber){
        this.session.user.selectedNumber = defaultNumber;
      }
      else {
        this.numberService
          .updateDefaultNumber({
            _id: this.session.user.customer.numbers[0]._id,
            customer: this.session.user.customer._id,
          })
          .pipe(takeUntil(this.unsubscribe))
          .subscribe();
      }
    }
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  UpdateIvrStatus() {
    let ivrStatus = this.ivrForm.getRawValue() as IVR;
    ivrStatus.forward = !ivrStatus.forward;
    this.customerService
      .updateIvrStatus(ivrStatus)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.toastNotification.success('IVR Status has been updated!', 'Ok');
      });
  }

  public onThemeToggle(): void {
    this.showThemePopup = !this.showThemePopup;
    this.toggleText = this.showThemePopup ? 'Hidе' : 'Show';
  }

  changeTheme(themeName: any) {
    const elem = document.getElementById('theme');
    if (elem) {
      elem.setAttribute('href', `assets/css/${themeName}.css`);
    }
    const body = document.getElementsByTagName('body')[0];
    if (body) {
      body.classList.toggle('theme-dark', !body.classList.contains('theme-dark'));
    }
    this.showThemePopup = false;
  }

  get isDarkMode() {
    const body = document.getElementsByTagName('body')[0];
    return body.classList.contains('theme-dark');
  }

  public activateClass(themeButtons: { active: boolean }) {
    themeButtons.active = !themeButtons.active;
  }

  public numberClick($event: Event) {
    $event.preventDefault();
    this.router.navigate(['/numbers']);
  }

  copyAndDial(event: MouseEvent): void {
    // Prevent the default link action
    event.preventDefault();
    const numberToCopy = this.session.user!.selectedNumber?.number;
    // Copy the number to the clipboard
    if (numberToCopy) {
      navigator.clipboard.writeText(numberToCopy).catch(err => {
        console.error('Failed to copy number: ', err);
      });
    }
  }  
}
