import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { fromEvent, merge, Observable, Subscription, timer } from 'rxjs';
import { switchMap, take, takeUntil, tap, throttleTime } from 'rxjs/operators';
import { LocalStorageService } from './core/services/local-storage.service';
import { BaseComponent } from './core/common/base.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseComponent implements OnInit, OnDestroy {
  title = 'relaygrid.app';

  userActivity: Observable<any> | undefined;
  userInactive: Subscription | undefined;

  constructor(public auth: AuthService, private storage: LocalStorageService) {
    super();
  }

  ngOnInit(): void {
    this.setUserActivity();
  }

  setUserActivity() {
    // Timeout period in milliseconds (15 minutes)
    const timeoutDuration = 15 * 60 * 1000;
    // Initialize observables for each event you want to listen for
    const clicks$ = fromEvent(document, 'click').pipe(takeUntil(this.destroyed$));
    const mouseMoves$ = fromEvent(document, 'mousemove').pipe(takeUntil(this.destroyed$));
    const keyUps$ = fromEvent(document, 'keyup').pipe(takeUntil(this.destroyed$));
    // Merge the observables
    const events$ = merge(clicks$, mouseMoves$, keyUps$).pipe(takeUntil(this.destroyed$));

    // Start a timer after each user event, and log out the user if no events occur for `timeout` milliseconds.
    this.userInactive = events$
      .pipe(
        takeUntil(this.destroyed$),
        // Throttling, instead of debouncing, to avoid delays in resetting the timer
        throttleTime(1000),
        // Switch to a timer observable, cancelling any ongoing timers if a new user action occurs
        switchMap(() => timer(timeoutDuration)),
        tap(() => {
          this.logoutUser();
        })
      )      
      .subscribe();
  }

  logoutUser() {
    this.storage.clearLocalStorage();
    this.auth.logout({
      openUrl(url) {
        window.location.replace(url);
      },
    });
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.userInactive?.unsubscribe();
  }
}
