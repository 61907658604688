import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SessionService } from '../core/services/session.service';
import { AppSettings } from 'src/assets/config/app-settings';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule    
  ],
  providers: [
    AppSettings,
    SessionService
  ]
})
export class AvailabilityModule { }
