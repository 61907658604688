@if (showLoader) {
  <div class="loader">
    <kendo-loader [type]="'converging-spinner'" [themeColor]="'info'" [size]="'medium'"> </kendo-loader>
  </div>
}

<div id="onboarding-container" class="content subscription d-flex flex-column flex-column-fluid">
  <div class="container-xxl">
    <div class="row mb-0 pb-0">
      <div class="col-md-12 text-center my-10">
        <h3 class="fs-3x fw-bolder text-primary">Onboarding <i class="fa-duotone fa-clipboard-list-check"></i></h3>
        <p class="fs-4" [innerHTML]="_message"></p>
      </div>

      <div class="d-flex justify-content-center mb-0">
        <kendo-stepper
          #stepper
          [steps]="steps"
          stepType="full"
          [(currentStep)]="currentStep"
          [linear]="false"
          (activate)="activate($event)"
          [style.width.px]="700"
          [style.height.px]="80"
          class="mb-0 pb-0"
          >
        </kendo-stepper>
      </div>
    </div>

    @if (event.progress > 0) {
      <div class="row justify-content-center mt-0 pt-0">
        <div class="col-md-8 text-center">
          <div class="left-align fs-9">
            <em>{{ event.message }}</em>
          </div>
          <kendo-progressbar [animation]="true" [value]="event.progress"></kendo-progressbar>
        </div>
        <br />
      </div>
    }

    <div class="content mt-6">
      @if (currentStep === status.plans) {
        <app-plans
          (changeStep)="goToStep($event)"
          (showLoader)="onChangeLoader($event)"
          (message)="message($event)"
          >
        </app-plans>
      }
      @if (currentStep === status.account) {
        <app-account
          (changeStep)="goToStep($event)"
          (showLoader)="onChangeLoader($event)"
          (message)="message($event)"
          >
        </app-account>
      }
      @if (currentStep === status.port || currentStep === status.esign) {
        <app-esign
          (changeStep)="goToStep($event)"
          (showLoader)="onChangeLoader($event)"
          (message)="message($event)"
          >
        </app-esign>
      }
      @if (currentStep === status.payment) {
        <app-payment
          (changeStep)="goToStep($event)"
          (showLoader)="onChangeLoader($event)"
          (message)="message($event)"
          >
        </app-payment>
      }
      @if (currentStep === status.invoice) {
        <app-invoice
          (changeStep)="goToStep($event)"
          (showLoader)="onChangeLoader($event)"
          (message)="message($event)"
          >
        </app-invoice>
      }
    </div>
  </div>
</div>
