<app-portal-header></app-portal-header>
<kendo-drawer-container class="portal-main">
  <kendo-drawer
    class="sidebar aside"
    #drawer
    [items]="items"
    [mini]="true"
    mode="push"
    [width]="266"
    [(expanded)]="expanded"
    (select)="onSelect($event)"
    [autoCollapse]="false"
    [isItemExpanded]="isItemExpanded"
    >
    <ng-template kendoDrawerHeaderTemplate>
      @if (expanded) {
        <div style="text-align: center">
          <div class="aside-toolbar flex-column-auto" id="kt_aside_toolbar">
            <div class="aside-user d-flex align-items-start justify-content-start py-5">
              @if (user) {
                <div class="symbol symbol-40px">
                  <img [src]="user.picture" alt="User" /><br />
                  <span class="text-success fw-bolder fs-8">{{ session!.user!.roles![0] }}</span>
                </div>
              }
              <div class="aside-user-info flex-row-fluid flex-wrap ms-3">
                <div class="d-flex">
                  <div class="flex-grow-1 me-2 d-flex align-items-start flex-column justify-content-start text-nowrap">
                    <a href="javascript:void()" class="text-white text-hover-primary fs-7 fw-bold"
                      >{{ session.user?.first }} {{ session.user?.last }}</a
                      >
                      <span class="text-gray-600 fw-bold d-block fs-8 mb-1">{{ user?.email || user?.name }}</span>
                      <span class="d-block fs-8 mb-1">{{ session.user?.customer?.company?.name }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="aside-search py-5">
                <div
                  id="kt_header_search"
                  class="d-flex align-items-center w-100"
                  data-kt-search-keypress="true"
                  data-kt-search-min-length="2"
                  data-kt-search-enter="enter"
                  data-kt-search-layout="menu"
                  data-kt-search-responsive="false"
                  data-kt-menu-trigger="auto"
                  data-kt-menu-permanent="true"
                  data-kt-menu-placement="bottom-start"
                  >
                  <form data-kt-search-element="form" class="w-100 position-relative" autocomplete="off">
                    <input type="hidden" />
                    <span class="svg-icon svg-icon-2 search-icon position-absolute top-50 translate-middle-y ms-4">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                        <rect
                          opacity="0.5"
                          x="17.0365"
                          y="15.1223"
                          width="8.15546"
                          height="2"
                          rx="1"
                          transform="rotate(45 17.0365 15.1223)"
                          fill="currentColor"
                          />
                          <path
                            d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                            fill="currentColor"
                            />
                          </svg>
                        </span>
                        <input
                          type="text"
                          class="search-input form-control ps-13 fs-8 h-40px"
                          name="search"
                          value=""
                          placeholder="Quick Search"
                          data-kt-search-element="input"
                          />
                          <span
                            class="position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-5"
                            data-kt-search-element="spinner"
                            >
                            <span class="spinner-border h-15px w-15px align-middle text-gray-400"></span>
                          </span>
                          <span
                            class="btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-4"
                            data-kt-search-element="clear"
                            >
                            <span class="svg-icon svg-icon-2 svg-icon-lg-1 me-0">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect
                                  opacity="0.5"
                                  x="6"
                                  y="17.3137"
                                  width="16"
                                  height="2"
                                  rx="1"
                                  transform="rotate(-45 6 17.3137)"
                                  fill="currentColor"
                                  />
                                  <rect
                                    x="7.41422"
                                    y="6"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    transform="rotate(45 7.41422 6)"
                                    fill="currentColor"
                                    />
                                  </svg>
                                </span>
                              </span>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </ng-template>
                <ng-template kendoDrawerFooterTemplate>
                  @if (expanded) {
                    <div class="aside-footer">
                      <div class="d-flex justify-content-between w-100">
                        <kendo-button
                          id="kt_aside_footer"
                          (click)="logout()"
                          class="btn btn-dark-only ms-6 me-3 w-100"
                          data-bs-toggle="tooltip"
                          data-bs-trigger="hover"
                          data-bs-dismiss-="click"
                          title="Sign-Out"
                          size="small"
                          >
                          <em class="fa-duotone fa-arrow-right-from-bracket me-3"></em>
                          <span class="btn-label text-nowrap">Sign-Out</span>
                        </kendo-button>
                        <kendo-button iconClass="fa-duotone fa-chevrons-left me-3" fillMode="flat" (click)="drawer.toggle()">
                        </kendo-button>
                      </div>
                    </div>
                  }
                  @if (!expanded) {
                    <div class="aside-footer row">
                      <div class="col">
                        <kendo-button
                          (click)="logout()"
                          iconClass="fa-duotone fa-arrow-right-from-bracket"
                          data-bs-toggle="tooltip"
                          data-bs-trigger="hover"
                          data-bs-dismiss-="click"
                          title="Sign-Out"
                          fillMode="flat"
                          class="ms-2"
                          >
                        </kendo-button>
                      </div>
                      <div class="col">
                        <kendo-button iconClass="fa-duotone fa-chevrons-right ms-2" fillMode="flat" (click)="drawer.toggle()">
                        </kendo-button>
                      </div>
                    </div>
                  }
                </ng-template>
              </kendo-drawer>
              <kendo-drawer-content>
                <router-outlet></router-outlet>
                <app-portal-footer class="footer-main"></app-portal-footer>
              </kendo-drawer-content>
            </kendo-drawer-container>
