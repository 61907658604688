import { Company, Customer } from './customer.model';

export enum OnboardingStatus {
  none = -1,
  plans = 0,
  account = 1,
  port = 2,
  esign = 3,
  payment = 4,
  invoice = 5,
  finished = 6
}

export interface User {
  _id?: string;
  externalId?: string;
  email?: string;
  first?: string;
  last?: string;
  customer?: Customer;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  mobile?: string;
  phone?: string;
  roles?: string[];
  onboardingStatus?: OnboardingStatus;
  selectedPlanCode?: string;
}
