import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GridModule } from '@progress/kendo-angular-grid';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { InputsModule } from '@progress/kendo-angular-inputs';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';

import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { PublicLayoutComponent } from './shared/layouts/public-layout/public-layout.component';
import { PortalLayoutComponent } from './shared/layouts/portal-layout/portal-layout.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from './shared/shared.module';
import { PortalHeaderComponent } from './shared/layouts/portal-layout/components/portal-header/portal-header.component';
import { PortalFooterComponent } from './shared/layouts/portal-layout/components/portal-footer/portal-footer.component';

import { LayoutModule } from '@progress/kendo-angular-layout';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { IconsModule } from '@progress/kendo-angular-icons';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { MenuModule } from '@progress/kendo-angular-menu';
import { PopupModule } from '@progress/kendo-angular-popup';
import * as config from '../assets/config/app-settings.json';
import { AppSettings } from 'src/assets/config/app-settings';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { LabelModule } from '@progress/kendo-angular-label';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { MenusModule } from './menus/menus.module';
import { ChatModule } from '@progress/kendo-angular-conversational-ui';
import { MessagesModule } from './messages/messages.module';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { AvailabilityComponent } from './availability/availability.component';
import { AvailabilityModule } from './availability/availability.module';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { AccountModule } from './account/account.module';
import { ChartsModule } from '@progress/kendo-angular-charts';
import 'hammerjs';
import { UserService } from './core/services/user.service';
import { SessionService } from './core/services/session.service';
import { concatMap, tap } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';
import { HttpService } from './core/services/http.service';
import { SubscriptionsModule } from './features/subscriptions/subscriptions.module';
import { SubscriptionsService } from './core/services/subscriptions.service';
import { InvoicesModule } from './invoices/invoices.module';
import { OnboardingModule } from './onboarding/onboarding.module';
import { EventsService } from './core/services/events.service';
import { ListViewModule } from "@progress/kendo-angular-listview";
import { ScrollViewModule } from '@progress/kendo-angular-scrollview';
import { HelpComponent } from './core/components/help/help.component';
import { SanitizeHtmlPipe } from './core/pipes/sanitize.html.pipe';
import { SmsModule } from './features/sms/sms.module';

import { AppSettings as Settings } from 'src/app/core/models/app-settings.model';
import * as appSettingsJson from 'src/assets/config/app-settings.json';
import { PhonePipe } from './core/pipes/phone.pipe';

const appSettings: Settings = appSettingsJson as unknown as Settings;

@NgModule({ declarations: [
        AppComponent,
        PublicLayoutComponent,
        PortalLayoutComponent,
        PortalHeaderComponent,
        PortalFooterComponent,
        AvailabilityComponent,
        HelpComponent,
        SanitizeHtmlPipe
    ],
    bootstrap: [AppComponent],
    exports: [FormsModule, GridModule, ButtonsModule, InputsModule], imports: [RouterModule,
        BrowserModule,
        AppRoutingModule,
        GridModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        GridModule,
        ButtonsModule,
        InputsModule,
        PopupModule,
        LabelModule,
        AuthModule.forRoot({
            // The domain and clientId were configured in the previous chapter
            domain: appSettings.domain,
            clientId: appSettings.clientId,
            authorizationParams: {
                redirect_uri: window.location.origin,
                // Request this audience at user authentication time
                audience: appSettings.audience,
                // Request this scope at user authentication time
                scope: 'openid profile read:current_user',
            },
            // Specify configuration for the interceptor              
            httpInterceptor: {
                allowedList: [
                    {
                        // Match any request that starts with apiBaseUrl e.g. 'http://localhost:3000/*' (note the asterisk)
                        uri: `${appSettings.apiBaseUrl}/*`,
                        tokenOptions: {
                            authorizationParams: {
                                // The attached token should target this audience
                                audience: appSettings.audience,
                                // The attached token should have these scopes
                                scope: 'openid profile read:current_user'
                            }
                        }
                    }
                ]
            },
        }),
        SharedModule,
        LayoutModule,
        IndicatorsModule,
        IconsModule,
        NavigationModule,
        MenuModule,
        AvailabilityModule,
        AccountModule,
        SortableModule,
        DropDownsModule,
        MenusModule,
        ChatModule,
        MessagesModule,
        UploadsModule,
        NotificationModule,
        ChartsModule,
        SubscriptionsModule,
        InvoicesModule,
        // DesignModule,
        OnboardingModule,
        ListViewModule,
        ScrollViewModule,
        SmsModule,
        PhonePipe], providers: [
        EventsService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
        {
            provide: APP_INITIALIZER,
            useFactory: settingsFactory,
            deps: [HttpClient, HttpService, AppSettings, AuthService, SessionService, UserService, SubscriptionsService],
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule { }

export function settingsFactory(
  httpClient: HttpClient,
  httpService: HttpService,
  settings: AppSettings,
  auth: AuthService,
  userSession: SessionService,
  userApiService: UserService
): () => Promise<void> {
  return (): Promise<void> => {
    return new Promise((resolve) => {
      httpClient
        .get('/assets/config/app-settings.json') // Return Observable, although is not very useful in this early phase
        .pipe(
          concatMap((r1) => {
            return auth.isAuthenticated$.pipe(
              tap((loggedIn) => {
                userSession.loggedIn = loggedIn;
              })
            );
          }),
          concatMap(() => {
            return auth.user$.pipe(
              tap((user) => {
                if (userSession.loggedIn) userSession.setUserInfo(user);
              })
            );
          }),
          concatMap(() => {
            if (userSession.loggedIn) {
              return auth.getAccessTokenSilently().pipe(
                tap((token) => {
                  if (userSession.loggedIn) {
                    userSession.SetAuthToken(token);
                  }
                })
              );
            } else {
              return Promise.resolve();
            }
          }),
          concatMap(() => {
            if (userSession.loggedIn && !userSession.user?.userId) {
              return userApiService.getUser();
            } else {
              return Promise.resolve();
            }
          })
        )
        .subscribe(() => {
          resolve();
        });
    });
  };
}
