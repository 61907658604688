import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { States } from 'src/app/core/models/states.model';
import { StatesService } from 'src/app/core/services/states.service';
import { SubscriptionsService } from 'src/app/core/services/subscriptions.service';
import { AuthService, User } from '@auth0/auth0-angular';
import { UserService } from 'src/app/core/services/user.service';
import { SessionService } from 'src/app/core/services/session.service';
import {
  loadStripe,
  Stripe,
  StripeCardElement,
  StripeElements,
  StripeError,
} from '@stripe/stripe-js';
import { AppSettings } from 'src/assets/config/app-settings';
import { OnboardingStatus } from 'src/app/core/models/user.model';
import { LoaderService } from 'src/app/core/services/loader.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('cardErrors', { static: false }) cardErrors!: ElementRef;

  @Output() changeStep = new EventEmitter<any>();
  @Output() showLoader = new EventEmitter<any>();
  @Output() message = new EventEmitter<string>();

  paying = false;
  elementsOptions = {
    clientSecret: '',
  };

  public OnboardingStatus = OnboardingStatus;

  public mask: string = '(000) 000-0000';
  public selectedState = '';
  public statesList: Array<States> = [];
  public placeHolder: States = {
    text: 'Select State',
    value: '',
  };

  public planCode!: string;
  public user: User | null | undefined;
  public userId: string | undefined;
  public auth0UserId: string | undefined;

  public stripe: Stripe | null = null;
  paymentElement: StripeCardElement | undefined;
  elements: StripeElements | undefined;
  public hasErrors = false;
  public error: StripeError | undefined;
  private scriptElement!: HTMLScriptElement;

  // Add a property for setupIntentClientSecret
  setupIntentClientSecret: string | null = null;

  constructor(
    private subscritionService: SubscriptionsService,
    private stateService: StatesService,
    public auth: AuthService,
    private userService: UserService,
    public session: SessionService,
    private settings: AppSettings,
    private loader: LoaderService
  ) {
    this.auth.user$.subscribe((x) => {
      this.user = x;
    });
    this.scriptElement = this.loader.loadScript('https://js.stripe.com/v3/', true);
  }

  ngOnInit(): void {
    this.userId = this.session.user?.userId;
    this.auth0UserId = this.session.user?.externalId;
    this.statesList = this.stateService.getStatesList();
    const message =
      "Please provide your Payment information to get started. Remember, all new customers are treated </br>to a complimentary one-month trial on us!";
    this.message.emit(message);
  }

  async ngAfterViewInit() {
    this.getUserDetails().subscribe((user) => {
      if (this.session!.user!.onboardingStatus! < OnboardingStatus.invoice) {
        if (user) {
          this.subscritionService
            .createPaymentIntent({
              amount: 4999,
              userId: this.userId,
              customerId: this.session.user?.customer?._id,
              priceId: user.selectedPlanCode,
            })
            .subscribe(async (response) => {
              // Update to use setupIntentClientSecret
              this.setupIntentClientSecret = response.setupIntentClientSecret!;
              this.stripe = await loadStripe(this.settings.stripeKey!);

              this.elements = this.stripe!.elements();

              this.paymentElement = this.elements.create('card', {
                hidePostalCode: false,
                value: {
                  postalCode: this.session.user?.customer?.company?.zip,
                },
                style: {
                  base: {
                    fontSize: '15px',
                    '::placeholder': {
                      color: '#000000',
                    },
                  }
                },
              });

              this.paymentElement!.mount('#payment-element');

              this.paymentElement!.on('change', (event: any) => {
                this.hasErrors = !event.complete;
              });
            });
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.loader.removeElement(this.scriptElement);
  }

  getUserDetails() {
    return this.userService.getUserByExternalId(true);
  }

  async onSubmit() {
    this.showLoader.emit(true);

    if (this.session!.user?.onboardingStatus! < OnboardingStatus.invoice) {
      // Confirm the card setup instead of the payment
      const result = await this.stripe!.confirmCardSetup(this.setupIntentClientSecret!, {
        payment_method: {
          card: this.paymentElement!,
          // Include any billing details here
        },
      });

      if (result.error) {
        // Handle errors here
        this.error = result.error;
        this.showLoader.emit(false);
      } else {
        // Card setup was successful
        this.subscritionService
          .createSubscription({
            userId: this.userId,
            customerId: this.session.user?.customer?._id,
          })
          .subscribe({
            next: (x) => {
              // Handle successful subscription creation
              this.session.user?.customer?.numbers?.push(x.number);
              this.session!.user!.onboardingStatus = x.user.onboardingStatus;
              this.changeStep.emit(OnboardingStatus.invoice);
            },
            complete: () => {
              this.showLoader.emit(false);
            },
          });
      }
    } else {
      this.showLoader.emit(false);
      this.changeStep.emit(4);
    }
  }

  previous() {
    this.changeStep.emit(2);
  }
}
