import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { User } from '@auth0/auth0-angular';
import { map, Observable } from 'rxjs';
import { OnboardingStatus } from '../models/user.model';
import { SessionService } from '../services/session.service';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class OnboardingGuard  {
  constructor(private router: Router, private session: SessionService, private userApiService: UserService) {}

  canActivate(
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (state.url.includes('/onboarding')) {
      return true;
    }

    return this.userApiService.getUserByExternalId(true).pipe(
      map((user: User) => {
        if (user && this.session.user) {
          if (user?.onboardingStatus === OnboardingStatus.plans) {
            this.router.navigate(['/onboarding/plans']);
            return false;
          }

          else if (user?.onboardingStatus === OnboardingStatus.account) {
            this.router.navigate(['/onboarding/account']);
            return false;
          }
                    
          else if (user?.onboardingStatus === OnboardingStatus.port || user?.onboardingStatus === OnboardingStatus.esign) {
            this.router.navigate(['/onboarding/esign']);
            return false;
          }

          else if (user?.onboardingStatus === OnboardingStatus.payment) {
            this.router.navigate(['/onboarding/payment']);
            return false;
          }

          else if (user?.onboardingStatus === OnboardingStatus.payment) {
            this.router.navigate(['/onboarding/payment']);
            return false;
          }

          else if (user?.onboardingStatus === OnboardingStatus.invoice) {
            this.router.navigate(['/onboarding/invoice']);
            return false;
          }

        }
        return true;
      })
    );
  }
}
  