import { Injectable } from '@angular/core';
import { empty, expand, map, of, reduce, scan, takeWhile } from 'rxjs';
import { OnboardingStatus } from '../models/user.model';
import { HttpService } from './http.service';
import { UserService } from './user.service';
import { AppSettings } from 'src/assets/config/app-settings';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class EsignService {
  private apiRelativeUrl = 'api/esign/document';

  constructor(
    private httpService: HttpService, 
    private userService: UserService, 
    private settings: AppSettings,
    private session: SessionService) {}

  getEsign() {
    const url = `api/esign/getEsign?userId=${this.session.user?.userId}`;
    return this.httpService.get<{ esign: any }>(url);
  }

  saveDocument(formData: FormData) {
    const url = `api/esign/saveDocument`;
    return this.httpService.post(url, formData);
  }

  savePort(formData: FormData) {
    const url = `api/esign/savePort`;
    return this.httpService.post(url, formData);
  }
}
