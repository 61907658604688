import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private renderer: Renderer2;

  constructor(private rendererFactory: RendererFactory2) {
    // We need to manually create a renderer since it's a service and won't have direct access to the component's view.
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  loadStylesheet(href: string): HTMLLinkElement {
    const link = this.renderer.createElement('link');
    this.renderer.setAttribute(link, 'rel', 'stylesheet');
    this.renderer.setAttribute(link, 'href', href);
    this.renderer.appendChild(document.head, link);
    return link;
  }

  loadScript(src: string, async: boolean = true): HTMLScriptElement {
    const script = this.renderer.createElement('script');
    this.renderer.setAttribute(script, 'src', src);
    if (async) {
      this.renderer.setAttribute(script, 'async', 'true');
    }
    this.renderer.appendChild(document.body, script);
    return script;
  }

  removeElement(element: Node) {
    this.renderer.removeChild(element.parentNode, element);
  }
}
