import { Component, ViewChild, ElementRef, Renderer2, EventEmitter, Output } from '@angular/core';
// @ts-ignore
import * as html2pdf from 'html2pdf.js';
import SignaturePad from 'signature_pad';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SessionService } from 'src/app/core/services/session.service';
import { FormatSettings } from '@progress/kendo-angular-dateinputs';
import { UserService } from 'src/app/core/services/user.service';
import { EsignService } from 'src/app/core/services/esign-document.service';
import { OnboardingStatus } from 'src/app/core/models/user.model';
import { SelectEvent, TabStripComponent } from '@progress/kendo-angular-layout';
import { forkJoin } from 'rxjs';
import { ThemeService } from '@progress/kendo-angular-charts/common/theme.service';

@Component({
  selector: 'app-esign',
  templateUrl: './esign.component.html',
  styleUrls: ['./esign.component.scss']
})
export class EsignComponent {
  @ViewChild('signaturePadCanvas', { static: false }) signaturePadCanvas: ElementRef<HTMLCanvasElement> | undefined;
  @ViewChild('contractDiv', { static: false }) contractDiv: ElementRef | undefined;
  @ViewChild('tabs', { static: false }) tabs: TabStripComponent | undefined;

  @Output() changeStep = new EventEmitter<any>();
  @Output() showLoader = new EventEmitter<any>();
  @Output() appInitialize: EventEmitter<any> = new EventEmitter();
  @Output() message = new EventEmitter<string>();

  public files = new Array<File>();

  public OnboardingStatus = OnboardingStatus;
  public portOption: string | null = null;

  signaturePad: any;
  contractForm = this.createLoaForm({
    name: null,
    email: null,
    address: null,
    city: null,
    state: null,
    zip: null,
    carrier: null,
    carrierBtn: null,
    numberToPort: null,
    date: null,
    customerId: null,
    userId: null,
    ein: null
  });
  user: any;

  portForm = this.createPortForm();

  public pdfUrl: string | null = null

  constructor(
    public session: SessionService,
    private renderer: Renderer2,
    private userService: UserService,
    public esign: EsignService) {
    forkJoin([
      this.esign.getEsign(),
      this.userService.getUser(true)
    ]).subscribe(([esignResponse, userResponse]) => {      
      this.portOption = esignResponse.esign?.portOption;
      if (esignResponse.esign?.link) {
        this.pdfUrl = esignResponse.esign.link;
      } else {
        this.initializeSignaturePad();
      }

      this.user = this.session.user;
      this.portForm = this.createPortForm();
      this.portForm.controls.billingStatementUrl.setValue(esignResponse.esign?.billingStatementUrl);
      const company = this.session.user?.customer?.company;
      this.contractForm = this.createLoaForm(company);
      this.contractForm?.controls.companyName.valueChanges.subscribe(value => {
        this.contractForm?.controls.companyName.setValue(value.toUpperCase(), { emitEvent: false });
      });
      this.contractForm.markAllAsTouched();
    });
  }

  createLoaForm(company: any): FormGroup {
    return new FormGroup({
      companyName: new FormControl(company?.name, [Validators.required]),
      email: new FormControl(this.user?.email, [Validators.required]),
      address: new FormControl(company?.address1, [Validators.required]),
      city: new FormControl(company?.city, [Validators.required]),
      state: new FormControl(company?.state, [Validators.required]),
      zip: new FormControl(company?.zip, [Validators.required]),
      carrier: new FormControl(null, [Validators.required]),
      carrierBtn: new FormControl(company?.phone, [Validators.required]),
      numberToPort: new FormControl(company?.phone, [Validators.required]),
      name: new FormControl(`${this.user?.first} ${this.user?.last}`, [Validators.required]),
      date: new FormControl<Date>(new Date(), [Validators.required]),
      customerId: new FormControl(this.user?.customer?._id),
      userId: new FormControl(this.user?.userId),
      ein: new FormControl(company?.ein, [Validators.required])
    });
  }

  createPortForm() {
    const portForm = new FormGroup({
      portOption: new FormControl(this.portOption, [Validators.required]),
      billingStatementFiles: new FormControl(this.files),
      customerId: new FormControl(this.user?.customer?._id),
      userId: new FormControl(this.user?.userId),
      billingStatementUrl: new FormControl(null)
    });

    portForm.controls.portOption!.valueChanges.subscribe(value => {
      if (value === 'port' && !this.portForm.controls.billingStatementUrl.value) {
        portForm.controls.billingStatementFiles!.setValidators([Validators.required]);
      } else {
        portForm.controls.billingStatementFiles!.setValidators([]);
      }
      portForm.controls.billingStatementFiles!.updateValueAndValidity();
    });

    return portForm;
  }

  ngOnInit() {
    const message = "Your number <strong><i>porting request</i></strong> with <strong>rgrid.io</strong> is in progress and it typically takes a few days to complete.<br/>Thank you for your patience and feel free to contact our support team if you have any questions."
    this.message.emit(message);
  }

  ngAfterViewInit() {
  }

  public initializeSignaturePad() {
    if (!this.pdfUrl && this.session.user?.onboardingStatus === OnboardingStatus.esign) {
      this.signaturePad = new SignaturePad(this.signaturePadCanvas!.nativeElement);
      this.signaturePad.penColor = "black";
      this.signaturePad.minWidth = 1;
      this.signaturePad.maxWidth = 1;

      this.renderer.setStyle(this.signaturePadCanvas?.nativeElement, 'border', '1px solid red');

      this.signaturePadCanvas!.nativeElement.width = this.signaturePadCanvas?.nativeElement.offsetWidth!;
      this.signaturePadCanvas!.nativeElement.height = this.signaturePadCanvas?.nativeElement.offsetHeight!;

      this.signaturePadCanvas!.nativeElement.addEventListener('pointerup', () => {
        if (!this.signaturePad.isEmpty()) {
          this.renderer.setStyle(this.signaturePadCanvas?.nativeElement, 'border', '1px solid black');
        } else {
          this.renderer.setStyle(this.signaturePadCanvas?.nativeElement, 'border', '1px solid red');
        }
      });
    }
  }

  clear($event: any) {
    this.signaturePad.clear();
    this.renderer.setStyle(this.signaturePadCanvas?.nativeElement, 'border', '1px solid red');
  }

  save() {
    if (this.signaturePad.isEmpty()) {
      alert("Please provide a signature first.");
    }
  }

  async exportToPdf() {
    this.showLoader.emit(true);
    if (this.signaturePad.isEmpty()) {
      alert("Please provide a signature first.");
    }

    const date = this.formatDateUTC(new Date());
    const options = {
      filename: `${this.session.user?.customer?._id}-${date}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2, logging: true, scrollY: -window.scrollY },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
      pages: 1
    };

    let elementToHide = document.querySelector('a.link-primary.fs-8') as any;
    elementToHide!.style.display = 'none';

    const blob = await html2pdf().from(this.contractDiv?.nativeElement).set(options).toPdf().output('blob')
    const formData = new FormData();
    formData.append('file', blob, `${this.session.user?.customer?._id}-${date}.pdf`);

    const formValues = this.contractForm?.getRawValue();
    Object.entries(formValues).forEach(([key, value]) => {
      formData.append(key, String(value));
    });

    let dateControlValue = this.contractForm?.controls.date?.value;
    let dateString = dateControlValue.toISOString();
    formData.set('date', dateString);

    elementToHide!.style.display = 'block';

    this.esign.saveDocument(formData)
      .subscribe((response: any) => {
        this.session.user!.onboardingStatus = response.user.onboardingStatus;
        this.showLoader.emit(false);
        this.changeStep.emit(OnboardingStatus.payment);
      });
  }

  private formatDateUTC(date: Date): string {
    const year = date.getUTCFullYear().toString();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
    const day = date.getUTCDate().toString().padStart(2, '0');
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const seconds = date.getUTCSeconds().toString().padStart(2, '0');
    return `${year}${month}${day}-${hours}${minutes}${seconds}`;
  }

  public dateFormat: FormatSettings = {
    displayFormat: "MM/dd/yyyy",
    inputFormat: "MM/dd/yy",
  };

  redo() {
    this.pdfUrl = null;
    setTimeout(() => this.initializeSignaturePad());
  }

  esignNext() {
    const userId = this.session.user?.userId;
    this.userService.updateUserOnboardingStatus(userId, OnboardingStatus.payment)
      .subscribe((response) => {
        this.changeStep.emit(response.user.onboardingStatus);
      });
  }

  esignPrevious() {
    this.tabs?.selectTab(0);
    this.changeStep.emit(OnboardingStatus.port);
  }

  portPrevious() {
    this.changeStep.emit(OnboardingStatus.account);
  }

  portNext() {    
    const formData = new FormData();

    const formValues = this.portForm?.getRawValue();

    Object.entries(formValues).forEach(([key, value]) => {
      if(key === 'billingStatementFiles')
        return;
      formData.append(key, String(value));
    });

    if(this.portForm.controls.portOption.value === 'port' && !this.portForm.controls.billingStatementUrl.value) {
      const date = this.formatDateUTC(new Date());
      formData.append('file', this.portForm.controls.billingStatementFiles.value![0], `${this.session.user?.customer?._id}-${date}.pdf`);        
    }

    this.showLoader.emit(true);

    this.esign.savePort(formData)
      .subscribe((response: any) => {
        this.session.user!.onboardingStatus = response.user.onboardingStatus;
        this.changeStep.emit(response.user.onboardingStatus);        
        this.tabs?.selectTab(1);
        if(!response.esign?.link) {          
          setTimeout(() => this.initializeSignaturePad());
        } else {
          this.pdfUrl = response.esign.link;
        }
        this.showLoader.emit(false);
      });
  }

  removeFile(){
    this.files = [];
    this.portForm.controls.billingStatementUrl.setValue(null);
    this.portForm.controls.billingStatementFiles.setValue(this.files);
  }

  tabSelect($event: SelectEvent){
    if($event.index === 1 && this.pdfUrl === null)
      this.initializeSignaturePad();
  }
}