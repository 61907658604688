import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlansComponent } from 'src/app/onboarding/subscriptions/plans/plans.component';
import { PaymentComponent } from 'src/app/onboarding/subscriptions/payment/payment.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { PaymentRoutingModule } from 'src/app/features/payment/payment-routing.module';
import { InvoiceComponent } from 'src/app/onboarding/invoice/invoice.component';
import { EsignComponent } from 'src/app/onboarding/esign/esign.component';
import { UserInfoComponent } from 'src/app/account/user-info/user-info.component';
import { PDFViewerModule } from '@progress/kendo-angular-pdfviewer';
import { FileSelectModule, UploadModule } from '@progress/kendo-angular-upload';

@NgModule({
  declarations: [PlansComponent, PaymentComponent, EsignComponent, InvoiceComponent, UserInfoComponent],
  imports: [
    PDFViewerModule,
    CommonModule,
    IndicatorsModule,
    LayoutModule,
    ButtonsModule,
    InputsModule,
    LabelModule,
    DropDownsModule,
    FormsModule,
    DateInputsModule,
    ReactiveFormsModule,
    PaymentRoutingModule,
    FileSelectModule
  ],
  exports: [PlansComponent, PaymentComponent, EsignComponent, InvoiceComponent, UserInfoComponent],
})
export class CommonsModule {}
