<div class="body-space content d-flex flex-column flex-column-fluid mt-0 pt-0" id="kt_content">
  <div class="post d-flex flex-column-fluid mt-0 pt-0" id="kt_post">
    <div id="kt_content_container" class="container-xxl mt-0 pt-0">
      <div class="row mt-0 pt-0">
        <div class="col-12 mt-0 pt-0">
          <div class="card mt-0 pt-0">
            <kendo-tabstrip class="r-tabs mt-0 mt-0 pt-0 shadow-sm">
              <kendo-tabstrip-tab title=" " [selected]="true">
                <ng-template kendoTabTitle>
                  <h3 class="card-title align-items-start flex-column">
                    <span class="card-label fw-bolder mb-1 fs-5">Payment Info</span>
                  </h3>
                </ng-template>
                <ng-template kendoTabContent>
                  @if (error) {
                    <div class="alert alert-danger" role="alert">
                      {{ error.message }}
                    </div>
                  }
                  <div class="m-10 mt-0">
                    <div class="text-muted mt-1 mb-4">Please fill in your credit card <i class="fa-duotone fa-credit-card"></i> info </div><br/>
                    @if (session!.user!.onboardingStatus! < OnboardingStatus.invoice) {
                      <div id="payment-element"></div>
                    }
                    @if (session!.user!.onboardingStatus! >= OnboardingStatus.invoice) {
                      <img
                        src="/assets/media/images/paid_in_full.jpg"
                        />
                    }
                  </div>
                </ng-template>
              </kendo-tabstrip-tab>
            </kendo-tabstrip>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="k-form-buttons col-12 px-3 m-0 mb-3 d-flex justify-content-end">
  <button type="button" (click)="previous()" kendoButton themeColor="primary" class="btn btn-light-info">
    <span class="fa-light fa-angle-left me-2"></span>&nbsp;Previous
  </button>
  <button
    type="button"
    (click)="onSubmit()"
    kendoButton
    themeColor="primary"
    class="btn btn-light-primary btn-form"
    [disabled]="hasErrors"
    >
    <span class="btn-label">Next</span>&nbsp;
    <span class="fa-light fa-angle-right me-2"></span>
  </button>
</div>
<br/>
<br/>