<kendo-appbar class="header p-0 justify-content-between" position="top">
  <kendo-appbar-section class="header-brand">
    <a href="javascript:void();">
      <img alt="Logo" src="assets/media/logos/Relaygrid6_white.svg" class="h-25px h-lg-45px" />
    </a>
  </kendo-appbar-section>

  <kendo-appbar-section
    class="container-fluid header-right py-3 py-lg-0 d-flex flex-column flex-lg-row align-items-center justify-content-lg-between"
    >
    <kendo-appbar-section class="page-title d-flex align-items-start flex-column me-5">
      <h1 class="d-flex flex-column text-dark fw-bolder mb-0 fs-3">{{ pagetitle || "" }}</h1>
    </kendo-appbar-section>

    <div class="d-flex">
      <kendo-appbar-section class="d-flex justify-content-end">
        <div class="toggle-fieldset">
          <form [formGroup]="ivrForm" class="d-flex">
            <kendo-formfield class="pe-3 d-flex align-items-center">
              <kendo-switch
                formControlName="forward"
                #forward
                class="w-75-px"
                onLabel="Enabled"
                offLabel="Disabled"
              ></kendo-switch>
            </kendo-formfield>

            @if (!ivrForm.get('forward')?.value) {
              <div class="k-form flex-container">
                <span class="badge bg-success me-2">Forward Calls To</span> &nbsp;
                <kendo-textbox
                  class="me-3"
                  formControlName="forwardNumber"
                  style="width: 20em"
                  [clearButton]="true"
                  #forwardNumber
                  >
                  <ng-template kendoTextBoxPrefixTemplate>
                    <em
                      #forwadtNo
                      (click)="help.togglePopup(forwadtNo, 'forward')"
                      class="ms-3 fs-4 fa-duotone fa-forward"
                      help="true"
                    ></em>
                  </ng-template>
                </kendo-textbox>
              </div>
            }
          </form>
        </div>
        <a class="link-primary" style="cursor:pointer"
          (click)="copyAndDial($event)"
          >
          <i class="fa-light fa-phone ms-1 me-2"></i>
        </a>

        @if (ivrForm.get('forward')?.value) {
          <button
            (click)="numberClick($event)"
            type="button"
            class="btn btn-outline-primary"
            >
            {{ this.session.user!.selectedNumber?.number | phone }}
            @if (this.session.user!.selectedNumber?.name) {
              <span class="ms-3 badge bg-primary">
                {{ this.session.user!.selectedNumber?.name }}</span
                >
              }
              <i class="fs-5 ms-4 me-1 fa-duotone fa-pen-to-square"></i>
            </button>
          }

          @if (!ivrForm.get('forward')?.value!) {
            <button
              kendoButton
              iconClass="fa-duotone fa-floppy-disks"
              class="btn btn-light-primary"
              size="small"
              (click)="UpdateIvrStatus()"
              >
              Save
            </button>
          }
        </kendo-appbar-section>

        <!-- <kendo-appbar-section class="theme-mode-main">
        <button class="btn btn-icon icon-35 btn-light-info" kendoButton #anchor (click)="onThemeToggle()">
          <em *ngIf="!isDarkMode" class="fa-duotone fa-sun-bright light"></em>
          <em *ngIf="isDarkMode" class="fa-duotone fa-moon-stars dark"></em>
          <span class="ms-2 label1">Theme</span>
        </button>

        <kendo-popup
          [anchor]="anchor"
          [margin]="margin"
          (anchorViewportLeave)="showThemePopup = false"
          *ngIf="showThemePopup"
          >
          <div
            class="content p-0 menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-bold theme-mode"
            >
            <ul class="theme-buttons">
              <li class="menu-item">
                <button
                  id="light-theme"
                  class="menu-link light-theme px-3 d-flex"
                  (click)="changeTheme('style.bundle')"
                  >
                  <span class="menu-icon">
                    <em class="fa-duotone fa-sun-bright fs-3 me-3"></em>
                  </span>
                  <span class="menu-title">Light</span>
                </button>
              </li>
              <li class="menu-item">
                <button
                  id="dark-theme"
                  class="menu-link dark-theme px-3 d-flex"
                  (click)="changeTheme('style-dark-bundle')"
                  >
                  <span class="menu-icon">
                    <em class="fa-duotone fa-moon-stars fs-3 me-3"></em>
                  </span>
                  <span class="menu-title">Dark</span>
                </button>
              </li>
            </ul>
          </div>
        </kendo-popup>
      </kendo-appbar-section> -->
    </div>
  </kendo-appbar-section>
</kendo-appbar>
