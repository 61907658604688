import { Component, inject } from '@angular/core';
import { AppSettings } from 'src/assets/config/app-settings';

@Component({
  selector: 'app-portal-footer',
  templateUrl: './portal-footer.component.html',
  styleUrls: ['./portal-footer.component.scss']
})
export class PortalFooterComponent {
  public settings = inject(AppSettings)
}
