import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { OnboardingComponent } from './onboarding.component';
import { EsignComponent } from './esign/esign.component';

const routes: Routes = [
  {
    path: ':step',
    component: OnboardingComponent,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OnboardingRoutingModule {}
