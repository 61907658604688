import { FormGroup, FormControl } from "@angular/forms";
import { IVR } from "src/app/core/models/customer.model";
import { IVRForm } from "../models/customer";

export const createIvrForm  = (ivr?: IVR) => {
  if (!ivr) ivr = new IVR();

  const ivrForm = new FormGroup<IVRForm>({
    _id: new FormControl(ivr._id!),
    forward: new FormControl(!ivr.forward!),
    forwardNumber: new FormControl(ivr.forwardNumber!)
  });

  return ivrForm;
}
