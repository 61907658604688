<div class="body-space content subscription d-flex flex-column flex-column-fluid">
  <div class="container-xxl">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bolder mb-1">Relaygrid Plan's</span>
              <span class="text-muted mt-1 fw-bold">Select your plan to start with relaygrid</span>
            </h3>
          </div>
        </div>
      </div>
    </div>

    <div class="row subscription-tiles">
      @if (subscriptions.length === 0) {
        @for (item of skeletonSubscription; track item) {
          <div class="col-xl-4">
            <div class="card">
              <div class="card-header bottom-border">
                <h3 class="card-title align-items-start flex-column">
                  <kendo-skeleton shape="text" animation="pulse" [width]="40"></kendo-skeleton>
                  <kendo-skeleton shape="text" animation="pulse" [width]="80" [height]="30"></kendo-skeleton>
                </h3>
              </div>
              <div class="card-body">
                <div class="w-100 mb-10">
                  @for (item of skeletonSubscriptionFeaturesList; track item) {
                    <div class="d-flex align-items-center justify-content-between mb-5"
                      >
                      <kendo-skeleton shape="text" animation="pulse" [width]="90"></kendo-skeleton>
                      <kendo-skeleton shape="circle" animation="pulse" [width]="10" [height]="10"></kendo-skeleton>
                    </div>
                  }
                </div>
                <kendo-skeleton shape="rectangle" animation="pulse" width="40%" height="30px"></kendo-skeleton>
              </div>
            </div>
          </div>
        }
      }
      @if (subscriptions.length > 0) {
        @for (subscription of subscriptions; track subscription) {
          <div class="col-xl-4">
            <div class="card">
              <div class="card-header bottom-border">
                <h3 class="card-title align-items-start flex-column">
                  <span class="card-label fw-bolder mb-1">{{ subscription.name }}</span>
                  <span class="fw-bolder text-primary" data-kt-plan-price-month="999"
                    data-kt-plan-price-annual="9999">
                    <span class="mb-2 text-primary">$ {{ subscription.price }}</span>
                    <span class="fw-bold opacity-50">/Mon</span>
                  </span>
                </h3>
              </div>
              @if (subscription.name === 'Level 1') {
                <div class="card-body">
                  <div class="w-100 mb-10">
                    <div class="d-flex align-items-center mb-5">
                      <span class="fw-bold text-gray-800 flex-grow-1 pe-3">Up to 10 Active Users</span>
                      <span class="svg-icon svg-icon-1 svg-icon-success">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="success">
                          <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="success" />
                          <path
                            d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                            fill="success" />
                          </svg>
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-5">
                        <span class="fw-bold text-gray-800 flex-grow-1 pe-3">Up to 30 Project Integrations</span>
                        <span class="svg-icon svg-icon-1 svg-icon-success">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="success">
                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="success" />
                            <path
                              d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                              fill="success" />
                            </svg>
                          </span>
                        </div>
                        <div class="d-flex align-items-center mb-5">
                          <span class="fw-bold text-gray-800 flex-grow-1 pe-3">Analytics Module</span>
                          <span class="svg-icon svg-icon-1 svg-icon-success">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="success">
                              <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="success" />
                              <path
                                d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                fill="success" />
                              </svg>
                            </span>
                          </div>
                          <div class="d-flex align-items-center mb-5">
                            <span class="fw-bold text-gray-400 flex-grow-1">Finance Module</span>
                            <span class="svg-icon svg-icon-1">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="success">
                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="success" />
                                <rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)"
                                  fill="success" />
                                  <rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)"
                                    fill="success" />
                                  </svg>
                                </span>
                              </div>
                              <div class="d-flex align-items-center mb-5">
                                <span class="fw-bold text-gray-400 flex-grow-1">Accounting Module</span>
                                <span class="svg-icon svg-icon-1">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="success">
                                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="success" />
                                    <rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)"
                                      fill="success" />
                                      <rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)"
                                        fill="success" />
                                      </svg>
                                    </span>
                                  </div>
                                  <div class="d-flex align-items-center mb-5">
                                    <span class="fw-bold text-gray-400 flex-grow-1">Network Platform</span>
                                    <span class="svg-icon svg-icon-1">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="success">
                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="success" />
                                        <rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)"
                                          fill="success" />
                                          <rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)"
                                            fill="success" />
                                          </svg>
                                        </span>
                                      </div>
                                      <div class="d-flex align-items-center">
                                        <span class="fw-bold text-gray-400 flex-grow-1">Unlimited Cloud Space</span>
                                        <span class="svg-icon svg-icon-1">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="success">
                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="success" />
                                            <rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)"
                                              fill="success" />
                                              <rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)"
                                                fill="success" />
                                              </svg>
                                            </span>
                                          </div>
                                        </div>
                                        <a href="javascript:void(0)"
                                          class="btn btn-primary d-inline-flex align-items-center justify-content-center"
                                          (click)="onSelectPlan(subscription)"> <em class="fa-light fa-angle-right me-2"></em> Select Plan </a>
                                        </div>
                                      }
                                      @if (subscription.name === 'Level 2') {
                                        <div class="card-body">
                                          <div class="w-100 mb-10">
                                            <div class="d-flex align-items-center mb-5">
                                              <span class="fw-bold text-gray-800 flex-grow-1 pe-3">Up to 10 Active Users</span>
                                              <span class="svg-icon svg-icon-1 svg-icon-success">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="success">
                                                  <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="success" />
                                                  <path
                                                    d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                                    fill="success" />
                                                  </svg>
                                                </span>
                                              </div>
                                              <div class="d-flex align-items-center mb-5">
                                                <span class="fw-bold text-gray-800 flex-grow-1 pe-3">Up to 30 Project Integrations</span>
                                                <span class="svg-icon svg-icon-1 svg-icon-success">
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="success">
                                                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="success" />
                                                    <path
                                                      d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                                      fill="success" />
                                                    </svg>
                                                  </span>
                                                </div>
                                                <div class="d-flex align-items-center mb-5">
                                                  <span class="fw-bold text-gray-800 flex-grow-1 pe-3">Analytics Module</span>
                                                  <span class="svg-icon svg-icon-1 svg-icon-success">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="success">
                                                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="success" />
                                                      <path
                                                        d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                                        fill="success" />
                                                      </svg>
                                                    </span>
                                                  </div>
                                                  <div class="d-flex align-items-center mb-5">
                                                    <span class="fw-bold text-gray-800 flex-grow-1 pe-3">Finance Module</span>
                                                    <span class="svg-icon svg-icon-1 svg-icon-success">
                                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="success">
                                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="success" />
                                                        <path
                                                          d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                                          fill="success" />
                                                        </svg>
                                                      </span>
                                                    </div>
                                                    <div class="d-flex align-items-center mb-5">
                                                      <span class="fw-bold text-gray-800 flex-grow-1 pe-3">Accounting Module</span>
                                                      <span class="svg-icon svg-icon-1 svg-icon-success">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="success">
                                                          <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="success" />
                                                          <path
                                                            d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                                            fill="success" />
                                                          </svg>
                                                        </span>
                                                      </div>
                                                      <div class="d-flex align-items-center mb-5">
                                                        <span class="fw-bold text-gray-400 flex-grow-1">Network Platform</span>
                                                        <span class="svg-icon svg-icon-1">
                                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="success">
                                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="success" />
                                                            <rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)"
                                                              fill="success" />
                                                              <rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)"
                                                                fill="success" />
                                                              </svg>
                                                            </span>
                                                          </div>
                                                          <div class="d-flex align-items-center">
                                                            <span class="fw-bold text-gray-400 flex-grow-1">Unlimited Cloud Space</span>
                                                            <span class="svg-icon svg-icon-1">
                                                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="success">
                                                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="success" />
                                                                <rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)"
                                                                  fill="success" />
                                                                  <rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)"
                                                                    fill="success" />
                                                                  </svg>
                                                                </span>
                                                              </div>
                                                            </div>
                                                            <a href="javascript:void(0)"
                                                              class="btn btn-primary d-inline-flex align-items-center justify-content-center"
                                                              (click)="onSelectPlan(subscription)"> <em class="fa-light fa-angle-right me-2"></em> Select Plan </a>
                                                            </div>
                                                          }
                                                          @if (subscription.name === 'Level 3') {
                                                            <div class="card-body">
                                                              <div class="w-100 mb-10">
                                                                <div class="d-flex align-items-center mb-5">
                                                                  <span class="fw-bold text-gray-800 flex-grow-1 pe-3">Up to 10 Active Users</span>
                                                                  <span class="svg-icon svg-icon-1 svg-icon-success">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="success">
                                                                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="success" />
                                                                      <path
                                                                        d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                                                        fill="success" />
                                                                      </svg>
                                                                    </span>
                                                                  </div>
                                                                  <div class="d-flex align-items-center mb-5">
                                                                    <span class="fw-bold text-gray-800 flex-grow-1 pe-3">Up to 30 Project Integrations</span>
                                                                    <span class="svg-icon svg-icon-1 svg-icon-success">
                                                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="success">
                                                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="success" />
                                                                        <path
                                                                          d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                                                          fill="success" />
                                                                        </svg>
                                                                      </span>
                                                                    </div>
                                                                    <div class="d-flex align-items-center mb-5">
                                                                      <span class="fw-bold text-gray-800 flex-grow-1 pe-3">Analytics Module</span>
                                                                      <span class="svg-icon svg-icon-1 svg-icon-success">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="success">
                                                                          <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="success" />
                                                                          <path
                                                                            d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                                                            fill="success" />
                                                                          </svg>
                                                                        </span>
                                                                      </div>
                                                                      <div class="d-flex align-items-center mb-5">
                                                                        <span class="fw-bold text-gray-800 flex-grow-1 pe-3">Finance Module</span>
                                                                        <span class="svg-icon svg-icon-1 svg-icon-success">
                                                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="success">
                                                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="success" />
                                                                            <path
                                                                              d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                                                              fill="success" />
                                                                            </svg>
                                                                          </span>
                                                                        </div>
                                                                        <div class="d-flex align-items-center mb-5">
                                                                          <span class="fw-bold text-gray-800 flex-grow-1 pe-3">Accounting Module</span>
                                                                          <span class="svg-icon svg-icon-1 svg-icon-success">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="success">
                                                                              <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="success" />
                                                                              <path
                                                                                d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                                                                fill="success" />
                                                                              </svg>
                                                                            </span>
                                                                          </div>
                                                                          <div class="d-flex align-items-center mb-5">
                                                                            <span class="fw-bold text-gray-800 flex-grow-1 pe-3">Network Platform</span>
                                                                            <span class="svg-icon svg-icon-1 svg-icon-success">
                                                                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="success">
                                                                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="success" />
                                                                                <path
                                                                                  d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                                                                  fill="success" />
                                                                                </svg>
                                                                              </span>
                                                                            </div>
                                                                            <div class="d-flex align-items-center">
                                                                              <span class="fw-bold text-gray-800 flex-grow-1 pe-3">Unlimited Cloud Space</span>
                                                                              <span class="svg-icon svg-icon-1 svg-icon-success">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="success">
                                                                                  <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="success" />
                                                                                  <path
                                                                                    d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                                                                    fill="success" />
                                                                                  </svg>
                                                                                </span>
                                                                              </div>
                                                                            </div>
                                                                            <a href="javascript:void(0)"
                                                                              class="btn btn-primary d-inline-flex align-items-center justify-content-center"
                                                                              (click)="onSelectPlan(subscription)"> <em class="fa-light fa-angle-right me-2"></em> Select Plan </a>
                                                                            </div>
                                                                          }
                                                                        </div>
                                                                      </div>
                                                                    }
                                                                  }
                                                                </div>
                                                              </div>
                                                            </div>
