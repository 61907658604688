<div class="row subscription-tiles">
  @for (subscription of subscriptions; track subscription) {
    <div class="col-xl-4 mt-6">
      <div class="card shadow m-2 custom-rounded">
        <div class="card-header">
          <h3 class="card-title align-items-start flex-column d-flex mb-0 pb-0">
            <div class="row">
              <div class="col">
                <span class="card-label fw-bolder fs-1 mb-0">{{ subscription.name }}</span>
              </div>
            </div>
            <span class="fs-3x fw-bolder text-primary" data-kt-plan-price-month="999" data-kt-plan-price-annual="9999">
              <span class="fs-1 mb-2 text-primary">$ {{ subscription.currencies[0].unitAmount }}</span>
              <span class="fs-3 fw-bold opacity-50">/ monthly</span>
            </span>
          </h3>
        </div>
        <div class="card-body">
          <!-- Button - enabled only if state is 'active' -->
          <button
            kendoButton
            [class]="subscription.state === 'active' ? 'btn-primary' : 'btn-secondary'"
            [disabled]="subscription.state !== 'active'"
            class="rounded-pill mb-6 text-light"
            (click)="onSelectPlan(subscription.priceId!)"
            >
            {{ subscription.state !== "active" ? "Coming Soon" : "Select " + subscription.name }}
          </button>
          <div class="w-100 mb-5">
            @for (desc of subscription.availableFeature; track desc) {
              <div class="d-flex align-items-center mb-5">
                <span class="svg-icon svg-icon-1 svg-icon-success">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="success">
                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="success" />
                    <path
                      d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                      fill="success"
                      />
                    </svg>
                  </span>
                  <span class="fw-bold fs-6 text-gray-300 flex-grow-1 pe-3 px-2">{{ desc }}</span>
                </div>
              }
              @for (desc of subscription.disabledFeature; track desc) {
                <div class="d-flex align-items-center mb-5">
                  <span class="svg-icon svg-icon-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="success">
                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="success" />
                      <rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)" fill="success" />
                      <rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)" fill="success" />
                    </svg>
                  </span>
                  <span class="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3 px-2">{{ desc }}</span>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    }

    <ng-template #currentPlanBtnTemplate>
      <button kendoButton class="me-3 mb-3 btn btn-light-primary" [routerLink]="['/dashboard']">
        Continue with current plan
      </button>
    </ng-template>
  </div>
